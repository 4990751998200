<template>
  <!-- Contents -->
  <div class="contents" id="contents">
    <div class="inner-sm">
      <!-- Event View Section -->
      <section class="board-view-section">
        <div class="board-view">
          <div class="view-header">
            <h5>{{newsDetail.title}}</h5>
            <div class="header-info">
              <span class="date">{{$moment(newsDetail.post_date).format('YYYY.MM.DD')}}</span>
            </div>
          </div>

          <div class="view-body">
            <TinyViewer :value="newsDetail.content"></TinyViewer>
            <!-- hash tag -->
            <div class="hash-tag-area">
              <ul class="hash-tag-wrap">
                <li v-for="(hash_tag,ht_index) in newsDetail.hash_tag_info" :key="ht_index">
                  <span class="hash-tag"># {{hash_tag.hash_tag}}</span>
                </li>
              </ul>
            </div>
            <!-- // hash tag -->
          </div>
          <div class="view-footer">
            <!--  첨부 파일 -->
            <div v-if="fileShow" class="attachment-area">
              <dl class="attachment-wrap">
                <dt>{{$t("content.about.News.txt08")}}</dt>
                <dd>
                  <ul class="attachment-list">
                    <template v-for="(file,index) in newsDetail.file_info">
                      <li v-if="file.code_attach_cata === 'AF103'" :key="index">
                          <a @click="downloadAttachFile(file.attach_file_path, file.display_file_name)"
                             href="#" class="file">
                            {{file.display_file_name}}</a>
                      </li>
                    </template>
                  </ul>
                </dd>
              </dl>
            </div>
            <!--  // 첨부 파일 -->
          </div>
        </div>
      </section>
      <!-- // Event View Section -->
      <!-- Paging -->
      <div class="paging-area">
        <ul class="paging">
          <li>
            <router-link :to="{name:'NewsList', params:{hashTagNo: this.hashTagNo}}" class="back">Back</router-link>
          </li>
          <!-- 101(PREV) 100(Current) 99(NEXT) -->
          <li>
            <template v-if="typeof(newsDetail.next_news_no) === 'undefined'">
              <button>PREV</button>
            </template>
            <template v-else>
              <router-link :to="{ name: 'NewsDetail'
                                , params: { newsNo: newsDetail.next_news_no, hashTagNo:hashTagNo } }"
                           class="prev">Prev
              </router-link>
            </template>
          </li>
          <li class="v-bar">
            <template v-if="typeof(newsDetail.prev_news_no) === 'undefined'">
              <button>NEXT</button>
            </template>
            <template v-else>
              <router-link :to="{ name: 'NewsDetail'
                              , params: { newsNo: newsDetail.prev_news_no, hashTagNo:hashTagNo } }"
                           class="next">Next
              </router-link>
            </template>
          </li>
        </ul>
      </div>
      <!-- Paging -->
    </div>
  </div>
  <!-- // CONTENTS -->
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { APIs, FilePaths } from '@/common/portalApi';
import TinyViewer from '@/components/base/TinyViewer.vue';

export default {
  name: 'NewsDetail',
  data() {
    return {
      newsDetail: {},
      prevDisabled: true,
      hashTagNo: 0,
      fileShow: false,
    };
  },
  mixins: [mixinHelperUtils],
  components: {
    TinyViewer,
  },
  created() {
    alert('dd')
    this.hashTagNo = this.$route.params.hashTagNo;
  },
  mounted() {
    this.retrieveNews();
  },
  watch: {
    // 라우트가 변경되면 메소드를 다시 호출
    // https://router.vuejs.org/kr/guide/essentials/dynamic-matching.html
    // https://router.vuejs.org/kr/guide/advanced/data-fetching.html
    // 관련 소스 : /src/router/newsRouter.js
    $route: 'retrieveNews',
  },
  methods: {
    // 뉴스 상세 조회
    retrieveNews() {
      const { newsNo, hashTagNo } = this.$route.params;
      this.portalApiClient({
        url: APIs.ABOUT_NEWS_DETAIL,
        data: {
          news_no: newsNo,
          hash_tag_no: hashTagNo,
        },
      }).then(({ data }) => {
        if (typeof data === 'object') {
          this.newsDetail = data;
          this.fileShow = (data.file_info.filter((file) => file.code_attach_cata === 'AF103')).length > 0;
        }
      });
    },
    downloadAttachFile(attachFilePath, displayFileName) {
      const fileURL = this.getFileURL(FilePaths.NEWS, attachFilePath);
      this.downloadFile(fileURL, displayFileName);
    },
  },
};
</script>

<style scoped>
li > button{
  width: 58px;
  height: 28px;
  cursor: default;
}

</style>
